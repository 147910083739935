<template>

    <div style="text-align: center;">

        <b-button @click="$router.push({ name: 'Weekly Report' })">
            Download Weekly Report
        </b-button>

    </div>

</template>

<script>
export default {
    name: "Audit"
}
</script>

<style scoped>

</style>